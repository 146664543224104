.remittance-header {
    padding: 10px 16px;
    margin-bottom: 30px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
}

.remittance-header-text {
    font-weight: 600;
    margin: 14px 0;
}

.remittance-template-button {
    width: 100%;
    height: 120px;
    font-size: 18px;
    margin-bottom: 30px;
}

.remittance-template-image {
    font-size: 2em;
}

.remittance-upload {
    height: 220px;
}


.table {
    padding: 10px 30px;
    background-color: #fff;
}

.header {
    padding: 10px 16px;
    margin-bottom: 30px;
    background-color: #fff;
}

.header-text {
    font-weight: 600;
    margin: 14px 0;
}

.ant-upload-list-picture-card-container, .ant-upload.ant-upload-select-picture-card {
    width: 450px;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
    object-fit: cover;
}

.green{
    background-color: green;
    border-color: green;
}
.wrapper{
  height: 100vh;
  text-align: center;
}

body{
    background: #ececec;
}

h2 {
  text-align: left;
}

.register-text {
  font-size: small;
}

.login-section {
  padding: 24px;
  background-color: #fff;
}

.ant-card.ant-card-bordered.cardForm {
  margin-top: 10px; 
}

.logo-section {
  background-color: #ffffff;
  padding: 24px;
}
.table {
    padding: 10px 30px;
    background-color: #fff;
}
.table-section {
    padding: 10px 16px;
    background-color: #fff; 
}

.icons {
    font-size: 20px;
}

.header {
    padding: 10px 16px;
    margin-bottom: 30px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 26%);
}

.detail-header-text {
    font-weight: 600;
    margin: 6px 0;
}

.menu {
    background-color: #f0f2f5;
}

.user-info {
    font-size: 14px;
}

.ant-tabs-tab {
    font-size: 14px;
}

.back-btn {
    margin-left: 15px;
}
.ant-layout-sider {
  min-height: 100vh;
}

.ant-layout-logo {
  height: 35px;
  background: rgb(93, 138, 171);
  margin: 10px;
  color: black;
  text-align: center;
  font-size: large;
}

#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.antd-breadcrumb{
  margin: 7px 0 -17px 24px!important; 
}

.search-space {
  margin: 28px 5px;
}
